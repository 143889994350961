/**
 * 咨讯类型枚举
 */
export const NewsEnum = {
  ZD: {
    scope: 10,
    name: "置顶",
  },
  TJ: {
    scope: 20,
    name: "推荐",
  },
  XW: {
    scope: 30,
    name: "新闻",
  },
  GG: {
    scope: 40,
    name: "公告",
  },
  HD: {
    scope: 50,
    name: "活动",
  },
};

// 时间段的状态枚举
export const TimeStatusEnum = {
  WAIT_START: "即将开始",
  STARTED: "进行中",
  END: "已结束",
};

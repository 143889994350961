<template>
  <div class="footer-pc" :style="{ backgroundColor: backgroundColor }">
    <div class="footer-container">
      <div class="footer-copyright">
        <div class="footer-copyright-item">
          Copyright©2024 点点文创 版权所有
        </div>
        <div
          class="footer-copyright-item href"
          @click="onHref('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          增值电信业务经营许可证：京B2-20241763 | 京ICP备2023002608号-2
        </div>
        <div class="footer-copyright-item">
          <span class="href" @click="onHref('https://jbts.mct.gov.cn/')"
            >12318全国文化市场举报</span
          >
          <span>|</span>
          <span
            class="href"
            @click="onHref('https://www.bjjubao.org.cn/index.html')"
            >网上有害信息举报</span
          >
          <span>|</span>
          <span class="href" @click="onHref('https://www.12377.cn/')"
            >违法和不良信息举报中心</span
          >
        </div>
      </div>
      <div class="footer-nav">
        <div class="footer-nav-item">用户协议</div>
        |
        <div class="footer-nav-item">隐私政策</div>
        |
        <div class="footer-nav-item">关于我们</div>
        |
        <div class="footer-nav-item">联系我们</div>
      </div>
      <div class="contact">
        <div class="contact-item">
          <a href="https://weibo.com/u/7835451389" target="_blank">
            <img class="contact-img" src="../assets/footer-weibo.png" alt="" />
          </a>
        </div>
        <div class="contact-item">
          <img class="contact-img" src="../assets/footer-qq.png" alt="" />
          <img
            class="contact-img-info"
            src="../assets/footer-qq-info.png"
            alt=""
          />
        </div>
        <div class="contact-item">
          <img class="contact-img" src="../assets/footer-weixin.png" alt="" />
          <img
            class="contact-img-info"
            src="../assets/footer-weixin-info.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-pc",
  props: {
    msg: String,
    backgroundColor: {
      type: String,
      default: "#1A2638",
    },
  },
  methods: {
    onHref(url) {
      if (url) window.open(url);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .footer-pc {
    display: none;
  }
}

.footer-pc {
  padding: 25px 121px 21px;
  width: 100%;
  height: 120px;
  //overflow: hidden;

  .footer-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer-copyright {
    text-align: left;
    cursor: pointer;

    .href {
      &:hover {
        text-decoration: underline;
      }
    }

    .footer-copyright-item {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.8;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .footer-nav {
    display: flex;
    align-items: center;
    gap: 10px;

    .footer-nav-item {
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    .contact-item {
      position: relative;

      &:nth-child(3n) {
        .contact-img {
          + .contact-img-info {
            right: 20px;
          }
        }
      }
    }

    .contact-img {
      width: 56px;
      height: 56px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        transform: scale(1.1);
        opacity: 0.9;

        + .contact-img-info {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .contact-img-info {
      position: absolute;
      bottom: 88px;
      z-index: 10;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      right: 0;
      pointer-events: none;
    }
  }
}
</style>

import request from "@/sheep/request";

const PublicConfigApi = {
  // 外部配置
  getPublicConfig: (params) => {
    return request({
      url: "/system/public-config/page",
      method: "GET",
      params,
    });
  },
};

export default PublicConfigApi;

<template>
  <div class="header-pc">
    <div class="header-pc-container">
      <img class="logo" src="../assets/logo.png" alt="" />
      <div class="header-pc-list">
        <router-link class="header-pc__item" active-class="active" to="/" exact>
          <span>首页</span>
        </router-link>
        <router-link
          class="header-pc__item"
          active-class="active"
          to="/news/list"
        >
          <span>新闻</span>
        </router-link>
        <router-link class="header-pc__item" active-class="active" to="/about">
          <span>关于</span>
        </router-link>
      </div>
      <div v-if="0" class="header-pc-login">
        <span class="header-pc-login__item">登录</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-pc",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .header-pc {
    display: none;
  }
}

.header-pc {
  padding: 0 120px;
  background-color: rgba(71, 95, 117);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;

  .header-pc-container {
    width: 1200px;
    margin: 0 auto;
    height: 88px;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 197px;
    height: 80px;
    align-self: flex-end;
  }

  .header-pc-list {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 40px;
    font-family: "zkHappyFont", serif;
    font-size: 18px;

    .header-pc__item {
      width: 119px;
      height: 88px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.6);

      > span {
        position: relative;
        top: 40px;
      }

      &.active {
        color: #fff;
        background: url("../assets/menu-active.png") top center no-repeat,
          url("../assets/menu-active-bg.png") center center no-repeat,
          url("../assets/menu-active-text-bg.png") bottom 8px center no-repeat;

        > span {
          //box-shadow: 0 0 18px 0 #8be3abbd;
        }
      }

      &:hover {
      }
    }
  }

  .header-pc-login {
    width: 119px;

    .header-pc-login__item {
      padding: 5px 10px;
      border: 1px solid rgba(238, 238, 238, 60);
      color: rgba(255, 255, 255, 0.6);
      border-radius: 15px;
      text-decoration: none;
    }
  }
}
</style>

<template>
  <div class="page-news-list">
    <div class="page-container">
      <div class="news-container">
        <div class="news-banner">
          <el-carousel height="450px">
            <el-carousel-item v-for="item in listTop" :key="item.id">
              <router-link
                :to="{
                  path: '/news/view',
                  query: { id: item.id, type: item.type },
                }"
              >
                <img class="news-banner__img" :src="item.picUrl" alt="" />
              </router-link>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="news-box">
          <div class="news-nav">
            <template v-for="(v, k) in Object.entries(NewsEnum)">
              <span
                class="news-nav__item"
                :key="k"
                v-if="k !== 0"
                :class="{ active: newsActive === v[1].scope }"
                @click="selectNews(v[1].scope)"
                >{{ v[1].name }}</span
              >
            </template>
          </div>
          <div class="news-list">
            <div
              class="news-list__item"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="news-list__item-title">
                <router-link
                  :to="{
                    path: '/news/view',
                    query: { id: item.id, type: item.type },
                  }"
                >
                  <span class="news-list__item-title__text">{{
                    item.title
                  }}</span>
                </router-link>
              </div>
              <div class="news-list__item-title__date">
                {{ formatDate(item.createTime) }}
              </div>
            </div>
          </div>
          <el-pagination
            small
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNo"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div class="footer-news">
        <footer-pc></footer-pc>
      </div>
    </div>

    <div class="page-container-mobile">
      <div class="news-mobile-main">
        <div class="news-mobile-container">
          <div class="news-mobile-nav">
            <template v-for="(v, k) in Object.entries(NewsEnum)">
              <span
                class="news-mobile-nav__item"
                :key="k"
                v-if="k !== 0"
                :class="{ active: newsActive === v[1].scope }"
                @click="selectNews(v[1].scope)"
                >{{ v[1].name }}</span
              >
            </template>
          </div>
          <div class="news-mobile-banner">
            <el-carousel
              height="228px"
              indicator-position="outside"
              arrow="always"
            >
              <el-carousel-item v-for="item in listTop" :key="item.id">
                <router-link
                  class="news-mobile-banner__item"
                  :to="{
                    path: '/news/view',
                    query: { id: item.id, type: item.type },
                  }"
                >
                  <img
                    class="news-mobile-banner__img"
                    :src="item.picUrl"
                    alt=""
                  />
                  <div class="news-mobile-banner__item-info">
                    <div class="news-mobile-banner__item-date">
                      {{ formatDate(item.createTime) }}
                    </div>
                    <div class="news-mobile-banner__item-title">
                      {{ item.title }}
                    </div>
                    <div class="news-mobile-banner__item-desc">
                      {{ item.title.substring(0, 15) }}
                    </div>
                  </div>
                </router-link>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="news-mobile-list">
            <router-link
              :to="{
                path: '/news/view',
                query: { id: item.id, type: item.type },
              }"
              class="news-mobile-list__item"
              v-for="(item, index) in list"
              :key="index"
            >
              <img
                class="news-mobile-list__item-cover"
                :src="item.picUrl"
                alt=""
              />
              <div class="news-mobile-list__item-info">
                <div class="news-mobile-list__item-date">
                  {{ formatDate(item.createTime) }}
                </div>
                <div class="news-mobile-list__item-title">
                  {{ item.title }}
                </div>
                <div class="news-mobile-list__item-desc">
                  {{ item.title.substring(0, 15) }}
                </div>
              </div>
            </router-link>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageNo"
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </div>
        </div>

        <div class="footer-mobile-box">
          <footer-mobile
            :download="false"
            :role-info="false"
            background-color="transparent"
          ></footer-mobile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import footerPc from "@/components/footerPc.vue";
import FooterMobile from "@/components/footerMobile.vue";
import NewsApi from "@/sheep/api/system/news";
import { formatDate } from "@/sheep/util";
import { NewsEnum } from "@/sheep/util/const";

export default {
  name: "news-list",
  computed: {
    NewsEnum() {
      return NewsEnum;
    },
  },
  components: { FooterMobile, footerPc },
  data() {
    return {
      newsActive: 20,
      listTop: [],
      list: [],
      pageNo: 1,
      pageSize: 20,
      pageTotal: 0,
    };
  },
  mounted() {
    this.getNewsTop();
    this.getNewsList();
  },
  methods: {
    formatDate,
    selectNews(status) {
      this.newsActive = status;
      this.pageNo = 1;
      this.getNewsList();
    },
    getNewsList() {
      NewsApi.getNewsList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        type: this.newsActive,
        status: 0,
      }).then((res) => {
        if (res?.code === 0) {
          this.list = res?.data?.list;
          this.pageTotal = res?.data?.total;
        } else {
          console.log(res);
        }
      });
    },
    getNewsTop() {
      NewsApi.getNewsList({
        pageNo: 1,
        pageSize: 8,
        type: 10,
        status: 0,
      }).then((res) => {
        if (res?.code === 0) {
          this.listTop = res?.data?.list;
        } else {
          console.log(res);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style>
.el-pagination {
  text-align: right;
  margin-top: 5px;

  .el-pagination__jump,
  .el-input__inner {
    color: #fff;
    opacity: 0.7;
  }

  button,
  li,
  .el-input__inner {
    background: transparent !important;
  }
}
</style>

<style scoped>
.page-news-list {
  @media screen and (max-width: 980px) {
    .page-container {
      width: 100%;
    }
  }

  .page-container {
    width: 1440px;
    height: 1080px;
    margin: 0 auto;
    color: #fff;
    background: url("../../assets/news-bg.png") top center no-repeat;
    background-size: 100%;
    position: relative;
  }

  .news-container {
    display: flex;
    background: rgba(26, 38, 56, 0.8);
    width: 1200px;
    height: 450px;
    margin: 0 auto;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    top: 294px;

    .news-banner {
      height: 100%;
      border-radius: 24px;
      overflow: hidden;
      flex: 1;
    }

    .news-banner__img {
      width: 100%;
      height: 100%;
    }
  }

  .news-box {
    flex: 1;
    padding: 40px 35px;

    .news-nav {
      display: flex;
      gap: 40px;
      color: #fff;
      cursor: pointer;
      margin-bottom: 20px;

      .news-nav__item {
        position: relative;
        font-size: 20px;

        &:hover {
          opacity: 0.8;
        }

        &.active {
          color: rgba(255, 218, 184, 1);

          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            inset: 0;
            margin: auto;
            box-shadow: 0 0 25px 10px rgba(255, 218, 184, 1);
          }
        }
      }
    }

    .news-list {
      padding: 20px 20px 0 0;
      overflow-y: auto;
      height: calc(100% - 50px);

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(57, 67, 77);
        border-radius: 10px;
      }

      .news-list__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
      }

      a {
        text-decoration: none;
      }

      .news-list__item-title {
        flex: 1;
        font-size: 22px;

        &:hover {
          text-decoration: #fff underline;
          opacity: 0.8;
        }
      }

      .news-list__item-title__text {
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.3;
        word-break: break-all;
        text-align: left;
      }

      .news-list__item-title__date {
        color: #666;
        margin-left: 20px;
        font-size: 14px;
      }
    }
  }

  .footer-news {
    position: absolute;
    bottom: 0;
  }

  .page-container-mobile {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .page-container {
      display: none;
    }

    .page-container-mobile {
      display: block !important;
      color: #fff;
      background: url("../../assets/mobile/about-m-bg.png") bottom center
        no-repeat;
      background-size: cover;
      background-color: #464140;
    }

    .news-mobile-main {
      padding-top: 64px;
      min-height: 100vh;
    }

    .news-mobile-container {
      padding: 10px 16px;
    }

    .news-mobile-nav {
      text-align: left;
      display: flex;
      align-content: center;
      gap: 24px;
      font-size: 18px;
      margin-bottom: 20px;

      .news-mobile-nav__item {
        &.active {
          color: #ffc38b;
        }
      }
    }

    .news-mobile-banner {
      margin-bottom: 10px;

      .news-mobile-banner__item {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      .news-mobile-banner__item-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        text-align: left;
        padding: 10px 16px;
        background: rgb(0 0 0 / 40%);
        border-radius: 12px 12px 0 0;
      }

      .news-mobile-banner__img {
        width: 100%;
        height: 100%;
      }

      .news-mobile-banner__item-date {
        font-size: 10px;
        opacity: 0.6;
      }

      .news-mobile-banner__item-title {
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.3;
        word-break: break-all;
        text-align: left;
        font-size: 16px;
        margin: 5px 0;
      }

      .news-mobile-banner__item-desc {
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.3;
        word-break: break-all;
        text-align: left;
        font-size: 12px;
        opacity: 0.9;
      }
    }

    .news-mobile-list {
      .news-mobile-list__item-cover {
        width: 180px;
        height: 117px;
        display: inline-block;
        border-radius: 12px;
      }

      .news-mobile-list__item {
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        text-align: left;
        margin-bottom: 23px;
      }

      .news-mobile-list__item-date {
        font-size: 10px;
        opacity: 0.6;
      }

      .news-mobile-list__item-title {
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.3;
        word-break: break-all;
        text-align: left;
        font-size: 16px;
        margin: 5px 0;
      }

      .news-mobile-list__item-desc {
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 1.3;
        word-break: break-all;
        text-align: left;
        font-size: 12px;
        opacity: 0.9;
      }
    }
  }
}
</style>

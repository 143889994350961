var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-news-list"},[_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"news-container"},[_c('div',{staticClass:"news-banner"},[_c('el-carousel',{attrs:{"height":"450px"}},_vm._l((_vm.listTop),function(item){return _c('el-carousel-item',{key:item.id},[_c('router-link',{attrs:{"to":{
                path: '/news/view',
                query: { id: item.id, type: item.type },
              }}},[_c('img',{staticClass:"news-banner__img",attrs:{"src":item.picUrl,"alt":""}})])],1)}),1)],1),_c('div',{staticClass:"news-box"},[_c('div',{staticClass:"news-nav"},[_vm._l((Object.entries(_vm.NewsEnum)),function(v,k){return [(k !== 0)?_c('span',{key:k,staticClass:"news-nav__item",class:{ active: _vm.newsActive === v[1].scope },on:{"click":function($event){return _vm.selectNews(v[1].scope)}}},[_vm._v(_vm._s(v[1].name))]):_vm._e()]})],2),_c('div',{staticClass:"news-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"news-list__item"},[_c('div',{staticClass:"news-list__item-title"},[_c('router-link',{attrs:{"to":{
                  path: '/news/view',
                  query: { id: item.id, type: item.type },
                }}},[_c('span',{staticClass:"news-list__item-title__text"},[_vm._v(_vm._s(item.title))])])],1),_c('div',{staticClass:"news-list__item-title__date"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createTime))+" ")])])}),0),_c('el-pagination',{attrs:{"small":"","current-page":_vm.pageNo,"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper","total":_vm.pageTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.pageNo=$event},"update:current-page":function($event){_vm.pageNo=$event}}})],1)]),_c('div',{staticClass:"footer-news"},[_c('footer-pc')],1)]),_c('div',{staticClass:"page-container-mobile"},[_c('div',{staticClass:"news-mobile-main"},[_c('div',{staticClass:"news-mobile-container"},[_c('div',{staticClass:"news-mobile-nav"},[_vm._l((Object.entries(_vm.NewsEnum)),function(v,k){return [(k !== 0)?_c('span',{key:k,staticClass:"news-mobile-nav__item",class:{ active: _vm.newsActive === v[1].scope },on:{"click":function($event){return _vm.selectNews(v[1].scope)}}},[_vm._v(_vm._s(v[1].name))]):_vm._e()]})],2),_c('div',{staticClass:"news-mobile-banner"},[_c('el-carousel',{attrs:{"height":"228px","indicator-position":"outside","arrow":"always"}},_vm._l((_vm.listTop),function(item){return _c('el-carousel-item',{key:item.id},[_c('router-link',{staticClass:"news-mobile-banner__item",attrs:{"to":{
                  path: '/news/view',
                  query: { id: item.id, type: item.type },
                }}},[_c('img',{staticClass:"news-mobile-banner__img",attrs:{"src":item.picUrl,"alt":""}}),_c('div',{staticClass:"news-mobile-banner__item-info"},[_c('div',{staticClass:"news-mobile-banner__item-date"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createTime))+" ")]),_c('div',{staticClass:"news-mobile-banner__item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"news-mobile-banner__item-desc"},[_vm._v(" "+_vm._s(item.title.substring(0, 15))+" ")])])])],1)}),1)],1),_c('div',{staticClass:"news-mobile-list"},[_vm._l((_vm.list),function(item,index){return _c('router-link',{key:index,staticClass:"news-mobile-list__item",attrs:{"to":{
              path: '/news/view',
              query: { id: item.id, type: item.type },
            }}},[_c('img',{staticClass:"news-mobile-list__item-cover",attrs:{"src":item.picUrl,"alt":""}}),_c('div',{staticClass:"news-mobile-list__item-info"},[_c('div',{staticClass:"news-mobile-list__item-date"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createTime))+" ")]),_c('div',{staticClass:"news-mobile-list__item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"news-mobile-list__item-desc"},[_vm._v(" "+_vm._s(item.title.substring(0, 15))+" ")])])])}),_c('el-pagination',{attrs:{"current-page":_vm.pageNo,"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper","total":_vm.pageTotal},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.pageNo=$event},"update:current-page":function($event){_vm.pageNo=$event}}})],2)]),_c('div',{staticClass:"footer-mobile-box"},[_c('footer-mobile',{attrs:{"download":false,"role-info":false,"background-color":"transparent"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";
import homeIndex from "../views/home/index.vue";
import newsList from "../views/news/list.vue";
import newsView from "../views/news/view.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: homeIndex,
  },
  {
    path: "/news/list",
    name: "newsList",
    component: newsList,
  },
  {
    path: "/news/view",
    name: "newsView",
    component: newsView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return new Promise((resolve) => {
      let startTime = null;

      function step(time) {
        if (!startTime) startTime = time;
        const timeElapsed = time - startTime;
        const duration = 500;
        const easeInOutQuad = (t) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        const timeFraction = timeElapsed / duration;
        const currentScroll = window.pageYOffset;
        const distance = 0 - currentScroll;
        const current = easeInOutQuad(timeFraction) * distance;
        window.scrollTo(0, current + currentScroll);
        if (timeElapsed < duration) {
          requestAnimationFrame(step);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }
      requestAnimationFrame(step);
    });
  },
});

export default router;

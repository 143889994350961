<template>
  <div class="page-news-view">
    <div class="page-container">
      <div class="news-view-main">
        <div class="breadcrumb">
          <span class="breadcrumb__item">首页</span>
          <span class="breadcrumb__item">新闻</span>
          <span class="breadcrumb__item">{{ typeInfo.name }}</span>
          <span class="breadcrumb__item">{{
            data.title?.substring(0, 20)
          }}</span>
        </div>
        <div class="news-view-container">
          <div class="news-view__content">
            <div class="news-view__content__title">
              {{ data.title }}
            </div>
            <div class="news-view__content__time">
              发布时间：{{ formatDate(data.createTime) }}
            </div>
            <div class="news-view__content__text" v-html="data.content"></div>
          </div>
          <!-- 推荐阅读 -->
          <div class="news-view__recommend">
            <div class="news-view__recommend__title">推荐阅读</div>
            <div class="news-view__recommend__list">
              <router-link
                class="news-view__recommend__list__item"
                v-for="(item, index) in list"
                :key="index"
                :to="{
                  path: '/news/view',
                  query: { id: item.id, type: data.type },
                }"
              >
                <div class="news-view__recommend__list__item__title">
                  {{ item.title }}
                </div>
                <div class="news-view__recommend__list__item__time">
                  {{ formatDate(item.createTime) }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-news">
        <footer-pc></footer-pc>
      </div>
    </div>

    <div class="page-container-mobile">
      <div class="news-mobile-view-container">
        <div class="news-mobile-view__content">
          <div class="news-mobile-view__content__title">
            {{ data.title }}
          </div>
          <div class="news-mobile-view__content__time">
            发布时间：{{ formatDate(data.createTime) }}
          </div>
          <div
            class="news-mobile-view__content__text"
            v-html="data.content"
          ></div>
        </div>
      </div>

      <div class="footer-mobile-box">
        <footer-mobile
          :download="false"
          :role-info="false"
          background-color="transparent"
        ></footer-mobile>
      </div>
    </div>
  </div>
</template>

<script>
import footerPc from "@/components/footerPc.vue";
import FooterMobile from "@/components/footerMobile.vue";
import NewsApi from "@/sheep/api/system/news";
import { formatDate } from "@/sheep/util";
import { NewsEnum } from "@/sheep/util/const";

export default {
  name: "news-list",
  components: { FooterMobile, footerPc },
  data() {
    return {
      data: {},
      list: [],
      typeInfo: {},
    };
  },
  watch: {
    "$route.query.id"() {
      this.getData();
    },
  },
  mounted() {
    Object.entries(NewsEnum).forEach((v) => {
      if (v[1].scope === Number(this.$route.query.type)) {
        this.typeInfo = v[1];
      }
    });
    this.getData();
    this.getOtherList();
  },
  methods: {
    formatDate,
    getData() {
      NewsApi.getNews({
        id: this.$route.query.id,
        status: 0,
      }).then((res) => {
        if (res?.code === 0) {
          this.data = res?.data;
        } else {
          console.log(res);
        }
      });
    },
    getOtherList() {
      NewsApi.getNewsList({
        pageNo: 1,
        pageSize: 20,
        type: this.typeInfo.scope,
        status: 0,
      }).then((res) => {
        if (res?.code === 0) {
          this.list = res?.data?.list;
        } else {
          console.log(res);
        }
      });
    },
  },
};
</script>

<style>
.news-mobile-view__content {
  img {
    max-width: 100%;
  }
}
</style>

<style scoped>
.page-news-view {
  text-align: left;

  .page-container {
    width: 1440px;
    height: 1080px;
    margin: 0 auto;
    color: #fff;
    background: url("../../assets/home-bg.png") top center no-repeat;
    background-size: 100%;
    position: relative;
  }

  .news-view-main {
    width: 1200px;
    height: 846px;
    margin: 0 auto;
    position: relative;
    top: 139px;
  }

  .breadcrumb {
    padding: 20px 0;
    font-size: 20px;

    .breadcrumb__item {
      display: inline-block;
      color: #fff;

      &:last-child {
        color: #fff;
      }

      &:after {
        content: ">";
        padding: 0 10px;
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }

  .news-view-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px 20px 60px;
    height: 755px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px 20px 0 0;
    color: #000;

    .news-view__content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .news-view__content__title {
        font-size: 30px;
        padding: 20px 0;
      }

      .news-view__content__time {
        font-size: 14px;
        color: #000;
        padding: 10px 0;
        color: rgba(102, 102, 102, 1);
      }

      .news-view__content__text {
        overflow: auto;
        font-size: 16px;
        color: #333;
        padding: 10px 20px 10px 0;
        line-height: 30px;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgb(161, 169, 176);
          border-radius: 10px;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .news-view__recommend {
      width: 300px;
      border-left: 1px solid #ccc;
      padding-left: 40px;
      margin-left: 20px;

      display: flex;
      flex-direction: column;

      .news-view__recommend__title {
        font-size: 18px;
        padding: 30px 0 40px;
      }

      .news-view__recommend__list {
        padding: 10px 20px 0 0;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgb(161, 169, 176);
          border-radius: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #fff;
        }

        .news-view__recommend__list__item {
          margin-bottom: 10px;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .news-view__recommend__list__item__title {
          font-weight: 500;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 1.5;
          word-break: break-all;
          text-align: left;
        }

        .news-view__recommend__list__item__time {
          font-size: 14px;
          padding: 10px 0;
          color: rgba(102, 102, 102, 1);
        }
      }
    }
  }

  .footer-news {
    position: absolute;
    bottom: 0;
  }

  .page-container-mobile {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .page-container {
      display: none;
    }

    .page-container-mobile {
      display: block !important;
      color: #fff;
      background: url("../../assets/mobile/about-m-bg.png") bottom center
        no-repeat;
      background-size: cover;
      background-color: #464140;
    }

    .news-mobile-view-container {
      padding-top: 64px;
    }

    .news-mobile-view__content {
      padding: 22px 20px;
      text-align: justify;
      font-size: 16px;

      .news-mobile-view__content__title {
        font-size: 30px;
      }

      .news-mobile-view__content__time {
        font-size: 14px;
        opacity: 0.6;
        margin: 10px 0;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="header-mobile">
    <div class="header-mobile__container filter">
      <div class="header-mobile__header">
        <img class="logo" src="../assets/logo.png" alt="" />
        <i
          class="el-icon-s-operation"
          v-if="!menuVisible"
          @click="toggleMenu"
        ></i>
        <i class="el-icon-close" v-if="menuVisible" @click="toggleMenu"></i>
      </div>
      <div
        class="header-mobile__control"
        :style="{
          height: menuVisible ? 3 * 48 + 10 + 'px' : '0',
        }"
      >
        <div class="header-mobile__list">
          <router-link
            class="header-mobile__item"
            active-class="active"
            to="/"
            exact
          >
            首页
          </router-link>
          <router-link
            class="header-mobile__item"
            active-class="active"
            to="/news/list"
          >
            新闻
          </router-link>
          <!--          <div class="header-mobile__item__sub">
            <div class="header-mobile__item">推荐</div>
          </div>-->
          <router-link
            class="header-mobile__item"
            active-class="active"
            to="/about"
          >
            关于
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-mobile",
  props: {},
  data() {
    return {
      menuVisible: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log("$route", to, from);
      this.menuVisible = false;
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .header-mobile {
    display: block !important;
  }
}

.header-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  font-family: "zkHappyFont", serif;

  .header-mobile__container {
    &:before {
      filter: blur(8px);
      background: rgba(71, 95, 117, 0.65);
      backdrop-filter: blur(8px);
    }
  }

  .header-mobile__header {
    padding: 5px 16px 10px;
    background-color: rgba(71, 95, 117);
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 24px;
  }

  .logo {
    height: 100%;
  }

  .header-mobile__control {
    height: 0;
    transition: height 1.2s;
    overflow: hidden;

    &.visible {
      height: 205px;
    }
  }

  .header-mobile__list {
    text-align: left;
    padding: 24px 12px;
    margin-top: -10px;

    .header-mobile__item {
      font-size: 18px;
      padding: 12px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.9);
      display: block;
    }

    .header-mobile__item__sub {
      font-size: 14px;
    }

    .active {
      background-color: rgba(204, 204, 204, 0.5);
      border-radius: 10px;
    }
  }
}
</style>

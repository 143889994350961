<template>
  <div class="page-home">
    <div class="page-container">
      <img class="role at" src="../../assets/home-role.png" alt="" />

      <img class="cutie at" src="../../assets/home-cutie.png" alt="" />

      <div class="msg-info at">
        <img class="msg-info__box" src="../../assets/home-msg-box.png" alt="" />
        <div class="msg-info__content">{{ msgContent }}</div>
      </div>

      <div class="download">
        <a class="download-btn" :href="downloadInfo.ios" target="_blank">
          <img src="../../assets/download-ios.png" alt="" />
        </a>
        <a class="download-btn" :href="downloadInfo.android" target="_blank">
          <img src="../../assets/download-android.png" alt="" />
        </a>
        <a class="download-btn" :href="downloadInfo.taptap" target="_blank">
          <img src="../../assets/download-taptap.png" alt="" />
        </a>
      </div>

      <img class="guide" src="../../assets/home-guide.png" alt="" />

      <img
        class="role-info it"
        src="../../assets/home-role-info-all.png"
        alt=""
      />

      <div class="footer-home filter">
        <footer-pc background-color="transparent"></footer-pc>
      </div>
    </div>

    <div class="page-container-mobile">
      <div class="footer-mobile-box">
        <footer-mobile
          :position="'fixed'"
          background-color="transparent"
          :download-info="downloadInfo"
        ></footer-mobile>
      </div>
    </div>
  </div>
</template>

<script>
import footerPc from "@/components/footerPc.vue";
import FooterMobile from "@/components/footerMobile.vue";
import PublicConfigApi from "@/sheep/api/system/home";

export default {
  name: "homeIndex",
  components: { FooterMobile, footerPc },
  data() {
    return {
      msg: "Hi，\n欢迎来到粟鼠旅行！",
      msgContent: "",
      downloadInfo: {},
    };
  },
  mounted() {
    this.showText();
    this.getPublicConfig();
  },
  methods: {
    // 实现js一段文本一个字一个字显示
    showText() {
      let i = 0;
      let timer = setInterval(() => {
        this.msgContent = this.msg.substring(0, i);
        i++;
        if (i > this.msg.length) {
          clearInterval(timer);
          setTimeout(() => {
            const ats = document.querySelectorAll(".at");
            const its = document.querySelectorAll(".it");
            const fts = document.querySelectorAll(".footer-home");
            ats.forEach((element) => {
              element.classList.add("fade-out");
            });
            fts.forEach((element) => {
              element.classList.add("fade-uot");
            });

            setTimeout(() => {
              its.forEach((element) => {
                element.classList.add("fade-in");
              });
            }, 2000);
          }, 2000);
        }
      }, 200);
    },
    getPublicConfig() {
      PublicConfigApi.getPublicConfig({
        type: 1,
        pageNo: 1,
        pageSize: 100,
        status: 0,
      }).then((res) => {
        if (res?.code === 0) {
          console.log(res);
          try {
            this.downloadInfo = JSON.parse(res?.data?.list?.[0]?.content);
          } catch (e) {
            console.error(e);
          }
        } else {
          console.log(res);
        }
      });
    },
  },
};
</script>

<style scoped>
.page-home {
  .page-container {
    width: 1440px;
    height: 1080px;
    margin: 0 auto;
    color: #fff;
    background: url("../../assets/home-bg.png") top center no-repeat;
    background-size: 100%;
    position: relative;

    .role {
      position: absolute;
      top: 315px;
      right: 0;
      width: 696px;
      height: 765px;
      z-index: 66;
    }

    .cutie {
      position: absolute;
      left: 2px;
      top: 132px;
      z-index: 11;
    }

    .msg-info {
      position: absolute;
      left: 121px;
      right: 0;
      top: 347px;
      width: 592px;

      .msg-info__box {
        width: 100%;
      }

      .msg-info__content {
        position: absolute;
        font-size: 44px;
        word-break: break-all;
        white-space: pre-wrap;
        text-align: left;
        line-height: 1.5;
        color: #88704d;
        top: 25%;
        padding: 0 95px;
        font-family: "zkHappyFont", serif;
      }
    }

    .download {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 7px;
      top: 815px;
      left: 120px;

      .download-btn {
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          transform: scale(1.05);
          opacity: 0.9;
          box-shadow: 0 0 15px 0 rgb(255 255 255 / 50%);
          border-radius: 18px;
        }
      }
    }

    .guide {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 7px;
      top: 873px;
      left: 124px;
    }

    .role-info {
      position: absolute;
      width: 467px;
      height: 343px;
      top: 503px;
      left: 889px;
    }

    .footer-home {
      position: absolute;
      top: 960px;
      z-index: 99;

      &:before {
        transition: 2s;
        background: url("../../assets/home-role.png") right 3px bottom 1px
            no-repeat,
          url("../../assets/home-bg.png") left -1px bottom 1px no-repeat;
        background-size: 696px 765px, 1440px 1080px;
      }

      &.fade-uot {
        &:before {
          background: url("../../assets/logo.png") right 3px bottom 1px
              no-repeat,
            url("../../assets/home-bg.png") left -1px bottom 1px no-repeat;
          background-size: 696px 765px, 1440px 1080px;
        }
      }
    }

    .at {
      opacity: 1;
      transition: opacity 2s ease-out;

      &.fade-out {
        opacity: 0;
      }
    }

    .it {
      opacity: 0;
      transform: scale(0.8);
      transition: 2s ease-out;

      &.fade-in {
        opacity: 1;
        transform: scale(1);
        animation: 2s growAndShrink 2s infinite alternate;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .page-container {
      display: none;
    }

    .page-container-mobile {
      display: block !important;
    }
  }

  .page-container-mobile {
    position: relative;
    height: 100vh;
    background: url("../../assets/mobile/home-bg.png") bottom center no-repeat;
    background-size: cover;
    display: none;
  }
}
</style>

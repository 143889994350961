<template>
  <div
    class="footer-mobile filter"
    :style="{ backgroundColor: backgroundColor, position: position }"
  >
    <div class="footer-container">
      <div class="role-info-all it" v-if="roleInfo">
        <img
          class="role-info"
          src="../assets/mobile/home-m-role-info.png"
          alt=""
        />
        <img class="role" src="../assets/mobile/home-m-role.png" alt="" />
      </div>

      <div class="download" v-if="download">
        <a class="download-btn" :href="downloadInfo.ios" target="_blank">
          <img src="../assets/download-ios.png" alt="" />
        </a>
        <a class="download-btn" :href="downloadInfo.android" target="_blank">
          <img src="../assets/download-android.png" alt="" />
        </a>
        <a class="download-btn" :href="downloadInfo.taptap" target="_blank">
          <img src="../assets/download-taptap.png" alt="" />
        </a>
      </div>

      <div class="contact">
        <div class="contact-item">
          <a href="https://weibo.com/u/7835451389" target="_blank">
            <img class="contact-img" src="../assets/footer-weibo.png" alt="" />
          </a>
        </div>
        <div class="contact-item">
          <img class="contact-img" src="../assets/footer-qq.png" alt="" />
          <img
            class="contact-img-info"
            src="../assets/mobile/footer-qq-info.png"
            alt=""
          />
        </div>
        <div class="contact-item">
          <img class="contact-img" src="../assets/footer-weixin.png" alt="" />
          <img
            class="contact-img-info"
            src="../assets/mobile/footer-weixin-info.png"
            alt=""
          />
        </div>
      </div>
      <div class="footer-nav">
        <div class="footer-nav-item">用户协议</div>
        <div class="footer-nav-item">隐私政策</div>
        <div class="footer-nav-item">关于我们</div>
        <div class="footer-nav-item">联系我们</div>
      </div>
      <div class="footer-copyright">
        <div class="footer-copyright-item">
          Copyright©2024 点点文创 版权所有
        </div>
        <div
          class="footer-copyright-item href"
          @click="onHref('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          增值电信业务经营许可证：京B2-20241763
        </div>
        <div
          class="footer-copyright-item href"
          @click="onHref('https://beian.miit.gov.cn/#/Integrated/index')"
        >
          京ICP备2023002608号-2
        </div>
        <div class="footer-copyright-item" style="margin-top: 8px">
          <span class="href" @click="onHref('https://jbts.mct.gov.cn/')"
            >12318全国文化市场举报</span
          >
          <span>|</span>
          <span
            class="href"
            @click="onHref('https://www.bjjubao.org.cn/index.html')"
            >网上有害信息举报</span
          >
          <span>|</span>
          <span class="href" @click="onHref('https://www.12377.cn/')"
            >违法和不良信息举报中心</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-mobile",
  props: {
    backgroundColor: {
      type: String,
      default: "#1A2638",
    },
    download: {
      type: Boolean,
      default: true,
    },
    roleInfo: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "relative",
    },
    downloadInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onHref(url) {
      if (url) window.open(url);
    },
  },
};
</script>

<style scoped>
.footer-mobile {
  position: relative;
  padding: 25px 16px;
  width: 100%;
  bottom: 0;
  border: none;

  &:before {
    filter: blur(1px);
    background: rgb(71 95 117 / 15%);
    backdrop-filter: blur(1px);
  }

  .footer-container {
    width: 100%;
    position: relative;
  }

  .role-info-all {
    position: absolute;
    bottom: calc(100% - 22px);
    padding: 22px 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;

    > img {
      max-width: 100%;
    }

    .role {
      width: 22vw;
      align-self: flex-end;
      margin-right: 5vw;
    }
  }

  .download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-bottom: 26px;

    .download-btn {
      width: 30%;
      cursor: pointer;
      transition: 0.3s;
      overflow: hidden;
      line-height: 0;

      img {
        width: 100%;
      }

      &:hover {
        transform: scale(1.05);
        opacity: 0.9;
        box-shadow: 0 0 15px 0 rgb(255 255 255 / 50%);
        border-radius: 18px;
      }
    }
  }

  .footer-copyright {
    margin-top: 8px;
    padding: 0 20px;

    .href {
      &:hover {
        text-decoration: underline;
      }
    }

    .footer-copyright-item {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 1.3;
      display: inline-flex;
      align-items: center;
      gap: 2px 5px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .footer-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .footer-nav-item {
      font-size: 12px;
      color: #fff;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 0 auto 16px;

    .contact-item {
      position: relative;

      &:nth-child(3n) {
        .contact-img {
          + .contact-img-info {
            left: calc(-50vw - 75%);
            bottom: 50px;
          }
        }
      }
    }

    .contact-img {
      width: 32px;
      height: 32px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        transform: scale(1.1);
        opacity: 0.9;

        + .contact-img-info {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .contact-img-info {
      width: 330px;
      height: 230px;
      object-fit: scale-down;
      position: absolute;
      bottom: 40px;
      z-index: 10;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      left: calc(-50vw + 140%);
      pointer-events: none;
    }
  }
}
</style>

import dayjs from "dayjs";

/**
 * 将一个整数转换为分数保留两位小数
 * @param {number | string | undefined} num 整数
 * @return {number} 分数
 */
export const formatToFraction = (num) => {
  if (typeof num === "undefined") return 0;
  const parsedNumber = typeof num === "string" ? parseFloat(num) : num;
  return parseFloat((parsedNumber / 100).toFixed(2));
};

/**
 * 将一个数转换为 1.00 这样
 * 数据呈现的时候使用
 *
 * @param {number | string | undefined} num 整数
 * @return {string} 分数
 */
export const floatToFixed2 = (num) => {
  let str = "0.00";
  if (typeof num === "undefined") {
    return str;
  }
  const f = formatToFraction(num);
  const decimalPart = f.toString().split(".")[1];
  const len = decimalPart ? decimalPart.length : 0;
  switch (len) {
    case 0:
      str = f.toString() + ".00";
      break;
    case 1:
      str = f.toString() + ".0";
      break;
    case 2:
      str = f.toString();
      break;
  }
  return str;
};

/**
 * 将一个分数转换为整数
 *
 * @param {number | string | undefined} num 分数
 * @return {number} 整数
 */
export const convertToInteger = (num) => {
  if (typeof num === "undefined") return 0;
  const parsedNumber = typeof num === "string" ? parseFloat(num) : num;
  // TODO 分转元后还有小数则四舍五入
  return Math.round(parsedNumber * 100);
};

/**
 * 时间日期转换
 * @param {dayjs.ConfigType} date 当前时间，new Date() 格式
 * @param {string} format 需要转换的时间格式字符串
 * @description format 字符串随意，如 `YYYY-mm、YYYY-mm-dd`
 * @description format 季度："YYYY-mm-dd HH:MM:SS QQQQ"
 * @description format 星期："YYYY-mm-dd HH:MM:SS WWW"
 * @description format 几周："YYYY-mm-dd HH:MM:SS ZZZ"
 * @description format 季度 + 星期 + 几周："YYYY-mm-dd HH:MM:SS WWW QQQQ ZZZ"
 * @returns {string} 返回拼接后的时间字符串
 */
export function formatDate(date, format = "YYYY-MM-DD HH:mm:ss") {
  // 日期不存在，则返回空
  if (!date) {
    return "";
  }
  // 日期存在，则进行格式化
  if (format === undefined) {
    format = "YYYY-MM-DD HH:mm:ss";
  }
  return dayjs(date).format(format);
}

/**
 * 构造树型结构数据
 *
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(
  data,
  id = "id",
  parentId = "parentId",
  // eslint-disable-next-line no-unused-vars
  children = "children",
  rootId = 0
) {
  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data));
  // 循环所有项
  const treeData = cloneData.filter((father) => {
    let branchArr = cloneData.filter((child) => {
      //返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 ? (father.children = branchArr) : "";
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData !== "" ? treeData : data;
}

/**
 * 重置分页对象
 *
 * TODO 芋艿：需要处理其它页面
 *
 * @param pagination 分页对象
 */
export function resetPagination(pagination) {
  pagination.list = [];
  pagination.total = 0;
  pagination.pageNo = 1;
}

/**
 * 将值复制到目标对象，且以目标对象属性为准，例：target: {a:1} source:{a:2,b:3} 结果为：{a:2}
 * @param target 目标对象
 * @param source 源对象
 */
export const copyValueToTarget = (target, source) => {
  const newObj = Object.assign({}, target, source);
  // 删除多余属性
  Object.keys(newObj).forEach((key) => {
    // 如果不是target中的属性则删除
    if (Object.keys(target).indexOf(key) === -1) {
      delete newObj[key];
    }
  });
  // 更新目标对象值
  Object.assign(target, newObj);
};

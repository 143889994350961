import request from "@/sheep/request";

const NewsApi = {
  // 资讯列表
  getNewsList: (params) => {
    return request({
      url: "/system/news/page",
      method: "GET",
      params,
    });
  },
  // 资讯详情
  getNews: (params) => {
    return request({
      url: "/system/news/get",
      method: "GET",
      params,
    });
  },
};

export default NewsApi;

// 开发环境配置

// console.log("env", process.env);

export default {
  baseUrl:
    process.env.NODE_ENV !== "development"
      ? process.env.VUE_APP_BASE_URL
      : process.env.VUE_APP_DEV_BASE_URL,
  apiPath: process.env.VUE_APP_ADMIN_API_PATH,
  staticUrl: process.env.VUE_APP_STATIC_URL,
  tenantId: process.env.VUE_APP_TENANT_ID,
  websocketPath: "",
};
